@import 'borders';
@import 'fonts';
@import 'palette';
@import 'spacing';

.input-border {
    position: relative;

    & > label > input {
        background-color: $light-color;
        border: $bw-0 solid $neutral-color-4;
        border-radius: 0;
        display: flex;
        font-size: $fs-1;
        height: $spacing-6;
        margin: $spacing--1 0 $spacing-6;
        padding: $spacing-02 $spacing-01;
        width: 100%;

        + span {
            font-size: $fs--1;
            left: $spacing-0;
            padding: 0 $spacing--1;
            position: absolute;
            top: -$spacing-0;
        }

        &:focus {
            border: $bw-1 solid currentColor;
            color: currentColor;
            outline: none;

            + span {
                color: currentColor;
            }

            &:placeholder-shown + span {
                top: 0;
                transform: translateY(-$spacing-0);
                transition: transform 0.5s;
            }
        }

        &:not(:focus) {
            color: $dark-color;

            + span {
                color: $neutral-color-4;
            }
        }
    }
}
